/* has already been loaded with index-above-the-fold.less
 * @import 'vendor/normalize';
 * @import 'vendor/h5bp-top';
 * @import 'vars';
 * @import 'reset';
 * @import 'typography';
 * @import 'webfonts/roboto';
 * @import 'webfonts/webdesans';
 * @import 'grid';
 * @import 'general';
 * @import 'elements';
 * @import 'elements-textfragments';
 * @import 'page';
 * @import 'taglist';
 * @import 'list';
 * @import 'vendor/h5bp-bottom';
 */
@keyframes showElement {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: inline-block;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes smallbounce {
  0% {
    bottom: -4px;
  }
  100% {
    bottom: 0;
  }
}
@keyframes bounceX {
  0% {
    right: -2px;
  }
  100% {
    right: 3px;
  }
}
@keyframes moveUp {
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes moveDown {
  100% {
    transform: translateY(200rem);
  }
}
@keyframes slidedown {
  0% {
    top: -5.6rem;
  }
  100% {
    top: 0;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
@keyframes open {
  0% {
    opacity: 0;
    transform: translate3d(calc(-100vw - 50%), 0, 0);
  }
  50% {
    opacity: 1;
    transform: translate3d(100px, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes close {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  50% {
    opacity: 1;
    transform: translate3d(-100px, 0, 0) scale3d(1.1, 1.1, 1);
  }
  100% {
    opacity: 0;
    transform: translate3d(calc(100vw + 50%), 0, 0);
  }
}
@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  80% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bounceOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 0;
    transform: scale(0.95);
  }
}
/**********************************************************************
 * Variables
 **********************************************************************/
/**********************************************************************
 * Mixins
 **********************************************************************/
/**
 * Mixin for primary text elements (e.g. headline, intro text in article...)
 */
/**
 * Font-size / line-height mixins
 */
/**
 * Cross-browser Web fonts
 */
@font-face {
  font-family: 'icons';
  src: url('../icons/icons_1604327755.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.icon {
  font-family: icons;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 3.2rem;
  line-height: 100%;
  position: relative;
}
@media (min-width:48em) {
  .icon {
    font-size: 4rem;
    line-height: 100%;
  }
}
.button .icon,
button .icon,
input .icon {
  font-size: 2rem;
  line-height: 100%;
}
.icon-bell {
  background-repeat: no-repeat;
  background-size: auto 50%;
  background-position: 2% 50%;
  background-image: var(--icon-bell);
}
.icon-video::before {
  content: '\e90c';
}
.icon-quiz::before {
  content: '\e90d';
}
.icon-slideshow::before {
  content: '\e90e';
}
.icon-live::before {
  content: '\e911';
}
.icon-thin-arrow-next::before {
  content: '\e90f';
}
.icon-thin-arrow-back::before {
  content: '\e910';
}
.icon-search::before {
  content: '\e626';
}
.icon-arrow-left::before {
  content: '\e600';
}
.icon-arrow-right::before {
  content: '\e601';
}
.icon-arrow-down::before {
  content: '\e607';
}
.icon-arrow-next::before {
  content: '\e623';
}
.icon-arrow-up::before {
  content: '\e608';
}
.icon-menu::before {
  content: '\e620';
}
.icon-new::before {
  content: '\e618';
}
.icon-top::before {
  content: '\e619';
}
.icon-glass-plus::before {
  content: '\e627';
}
.icon-glass-minus::before {
  content: '\e628';
}
.icon-close::before {
  content: '\e625';
}
.icon-rating-full::before {
  content: '\e657';
}
.icon-rating-quarter3::before {
  content: '\e656';
}
.icon-rating-half::before {
  content: '\e655';
}
.icon-rating-quarter1::before {
  content: '\e654';
}
.icon-rating-empty::before {
  content: '\e653';
}
.icon-time::before {
  content: '\e622';
}
.icon-reload::before {
  content: '\e904';
}
.icon-bmirechner::before {
  content: '\e673';
}
.icon-home::before {
  content: '\e905';
}
.icon-thumbsup::before {
  content: '\e901';
}
.icon-thumbsdown::before {
  content: '\e902';
}
.icon-email::before {
  content: var(--icon-email-code);
}
.icon-position::before {
  content: '\e631';
}
.icon-bullet::before {
  content: '\e64b';
}
.icon-wind-N::before {
  content: '\e636';
}
.icon-wind-NNO::before {
  content: '\e642';
}
.icon-wind-NO::before {
  content: '\e637';
}
.icon-wind-ONO::before {
  content: '\e646';
}
.icon-wind-O::before {
  content: '\e638';
}
.icon-wind-OSO::before {
  content: '\e647';
}
.icon-wind-SO::before {
  content: '\e639';
}
.icon-wind-SSO::before {
  content: '\e643';
}
.icon-wind-S::before {
  content: '\e63a';
}
.icon-wind-SSW::before {
  content: '\e644';
}
.icon-wind-SW::before {
  content: '\e63b';
}
.icon-wind-WSW::before {
  content: '\e648';
}
.icon-wind-W::before {
  content: '\e63c';
}
.icon-wind-WNW::before {
  content: '\e649';
}
.icon-wind-NW::before {
  content: '\e632';
}
.icon-wind-NNW::before {
  content: '\e645';
}
.icon-no-data::before {
  content: '\e63e';
}
.icon-spin::before {
  animation: 1s linear 0s infinite spin;
  color: var(--cp-grey-light-3);
  content: '\e63f';
  cursor: default;
  display: block;
  font-size: 3.2rem;
  line-height: 32px;
}
.icon-freemail::before {
  content: '\e908';
}
.icon-alc::before {
  content: '\e65f';
}
.icon-boerse::before {
  content: '\e671';
}
.icon-browser::before {
  content: '\e670';
}
.icon-cal::before {
  content: '\e669';
}
.icon-cam::before {
  content: '\e64c';
}
.icon-cinema::before {
  content: '\e64e';
}
.icon-doc::before {
  content: '\e667';
}
.icon-download::before {
  content: '\e65a';
}
.icon-driverlicence::before {
  content: '\e66b';
}
.icon-dsl::before {
  content: '\e66e';
}
.icon-game::before {
  content: '\e630';
}
.icon-heart::before {
  content: '\e65c';
}
.icon-horoscope::before {
  content: '\e66d';
}
.icon-immo::before {
  content: '\e665';
}
.icon-jam::before {
  content: '\e65d';
}
.icon-job::before {
  content: '\e663';
}
.icon-lex::before {
  content: '\e661';
}
.icon-mobile::before {
  content: '\e64f';
}
.icon-music::before {
  content: '\e660';
}
.icon-navi::before {
  content: '\e65e';
}
.icon-webcent::before {
  content: '\e90b';
}
.icon-hot::before {
  content: '\e900';
}
.icon-secu::before {
  content: '\e662';
}
.icon-single::before {
  content: '\e66a';
}
.icon-soccer::before {
  content: '\e666';
}
.icon-strom::before {
  content: '\e66c';
}
.icon-tv::before {
  content: '\e65b';
}
.icon-weathertool::before {
  content: '\e664';
}
.icon-zoom::before {
  content: '\e668';
}
.icon-calender::before {
  content: '\e60d';
}
.icon-arrow::before {
  content: '\e616';
}
.icon-schuetze::before {
  content: '\e61d';
}
.icon-steinbock::before {
  content: '\e61e';
}
.icon-wassermann::before {
  content: '\e61f';
}
.icon-fische::before {
  content: '\e621';
}
.icon-widder::before {
  content: '\e62a';
}
.icon-stier::before {
  content: '\e62b';
}
.icon-zwilling::before {
  content: '\e62c';
}
.icon-krebs::before {
  content: '\e62d';
}
.icon-loewe::before {
  content: '\e62e';
}
.icon-jungfrau::before {
  content: '\e62f';
}
.icon-gender::before {
  content: '\e633';
}
.icon-waage::before {
  content: '\e634';
}
.icon-skorpion::before {
  content: '\e635';
}
.icon-his::before {
  content: '\e641';
}
.icon-her::before {
  content: '\e640';
}
.icon-pause::before {
  content: '\e903';
}
.icon-change::before {
  content: '\e60f';
}
.icon-walk::before {
  content: '\e61a';
}
.icon-bike::before {
  content: '\e61b';
}
.icon-car::before {
  content: '\e61c';
}
.icon-check::before {
  content: '\e63d';
}
.icon-warning::before {
  content: '\e64a';
}
.icon-info::before {
  content: '\e672';
}
.icon-lotto::before {
  content: '\e906';
}
.icon-demail::before {
  content: '\e907';
}
.icon-plug::before {
  content: '\e909';
}
.icon-simcard::before {
  content: '\e90a';
}
.icon-webstory::before {
  content: '\e912';
}
.icon-preview::before {
  content: '\e629';
}
.social-btn-colors .sb-mail {
  background-color: var(--cp-grey-light-1);
}
.social-btn-colors .sb-twitter {
  background-color: var(--system-black);
}
.social-btn-colors .sb-pocket {
  background-color: var(--cp-pocket);
}
.social-btn-colors .sb-facebook {
  background-color: var(--cp-facebook);
}
.social-btn-colors .sb-link {
  background-color: var(--cp-grey-light-1);
}
.social-btn-colors .sb-blog {
  background-color: var(--cp-grey-light-1);
}
.social-btn-colors .sb-xing {
  background-color: var(--cp-xing);
}
.social-btn-colors .sb-vine {
  background-color: var(--cp-vine);
}
.social-btn-colors .sb-instagram {
  background-color: var(--cp-instagram);
}
.social-btn-colors .sb-linked {
  background-color: var(--cp-linkedin);
}
.social-btn-colors .sb-pinterest {
  background-color: var(--cp-pinterest);
}
.social-btn-colors .sb-youtube {
  background-color: var(--cp-youtube);
}
@page {
  size: a4;
}
@page :right {
  content: '';
}
@page :left {
  content: '';
}
@media print {
  .header,
  ui-social,
  .switcher,
  .co-container,
  .adv-generic-container,
  .content__meta,
  .content__info,
  .content__time,
  .coc-sky,
  .copyright,
  .article__startgallery-wrapper,
  .article-video,
  .article-video-object,
  .article-inner-video-object,
  .icon-glass-plus,
  .additionalArticles,
  .mod-tool,
  .tools,
  .topicWrapper,
  .channel-bar,
  .breadcrumb {
    display: none !important;
  }
  .article__featured-image {
    text-shadow: none;
    background-color: #fff !important;
  }
  .article-slideshow {
    background-color: #fff !important;
    color: #333 !important;
  }
  .co-container {
    border: 0 !important;
  }
  .content__headline {
    padding: 0;
    text-shadow: none !important;
    position: relative !important;
  }
  .teaser[data-mod-name='teaser'].promo {
    display: none;
  }
  [data-mod-name='teaser'].extension-promotion {
    display: none;
  }
  .slideshow-headline {
    background-color: #333;
    background-image: none;
    position: relative;
  }
  .slideshow-headline,
  .slideshow-description {
    color: #333 !important;
    padding: 0 !important;
    text-shadow: none !important;
    background: #fff none !important;
  }
  .slideshow-headline a,
  .slideshow-description a {
    color: #333 !important;
  }
  [data-mod-name='teaser'] .teaser-wrapper {
    margin-right: 0 !important;
  }
  [data-mod-name='teaser'] .teaser-wrapper .teaser-image .icon {
    display: none;
  }
  p {
    page-break-after: avoid;
  }
  a {
    word-wrap: break-word;
  }
  img {
    page-break-inside: avoid;
  }
  .teaser-pack .row .col {
    float: left;
  }
}
@media print and (min-width:64em) {
  [data-mod-name='teaser'] .teaser-wrapper .teaser-image {
    margin: 0 !important;
  }
}
@media print and (color) {
  article {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
body.login {
  background-color: var(--white);
  direction: ltr;
  overflow: auto;
  text-align: left;
  border-top: var(--login-top-border-width) solid var(--brand);
}
body.login.agb .logo-wrapper {
  max-width: 860px;
}
body.login.agb .content-wrapper {
  max-width: 860px;
  margin: 0 0 10rem;
  width: auto;
}
@media (min-width:48em) {
  body.login.agb .content-wrapper {
    margin: 0 2.4rem 10rem;
  }
}
body.login.agb .content-wrapper .headline {
  font-family: var(--font-family-sans-serif);
  font-weight: 700;
}
body.login.agb .button-fixed {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 90px;
  text-align: center;
  background-color: var(--white);
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.4);
}
body.login.agb .button-fixed .button {
  display: inline-block;
  padding: 0.8rem 1.6rem;
  margin: 2.4rem 2rem;
  line-height: 2.2rem;
  height: auto;
}
body.login .logo-wrapper {
  text-align: center;
  background-color: var(--login-logo-bg-color);
  padding: var(--login-logo-padding);
  margin: var(--login-logo-margin);
}
body.login .logo-wrapper::before,
body.login .logo-wrapper::after {
  content: ' ';
  display: table;
}
body.login .logo-wrapper::after {
  clear: both;
}
body.login .logo-wrapper .logo-image {
  display: inline-block;
  background-repeat: no-repeat;
  width: 100%;
  height: var(--login-logo-height);
  background-image: var(--login-logo-bg-img);
  background-position: var(--login-logo-bg-pos);
  background-size: var(--login-logo-bg-size);
}
body.login .content-wrapper {
  margin: 0 auto;
  width: 32rem;
  box-sizing: border-box;
}
body.login .content-wrapper .form {
  padding: 0 2rem;
}
body.login .content-wrapper .form::before,
body.login .content-wrapper .form::after {
  content: ' ';
  display: table;
}
body.login .content-wrapper .form::after {
  clear: both;
}
body.login .content-wrapper .form input {
  margin-right: 0;
  margin-bottom: 1.2rem;
  width: 100%;
}
body.login .content-wrapper .form input[type='password'] {
  margin-bottom: 0.4rem;
}
body.login .content-wrapper .form.error .intro {
  color: var(--notification-error);
}
body.login .content-wrapper .form.error input {
  border-color: var(--notification-error);
}
body.login .content-wrapper .h1 {
  margin: 0 0 1.6rem;
  font-size: 2.4rem;
  line-height: 3.2rem;
}
body.login .content-wrapper .intro {
  margin: 0 0 1.2rem;
  font-family: var(--font-family-sans-serif);
  font-size: 1.4rem;
  line-height: 1.8rem;
}
body.login .content-wrapper .intro .mandant {
  white-space: nowrap;
}
body.login .content-wrapper .passwort-hint {
  float: right;
  font-family: var(--font-family-sans-serif);
  font-size: 1.4rem;
  line-height: 1.8rem;
}
body.login .content-wrapper .button {
  display: block;
  margin-top: 1.1rem;
  width: 100%;
  float: left;
}
body.login .content-wrapper .nomail-info {
  border-top: 0.1rem solid var(--separator-default);
  margin-top: 0.8rem;
  padding: 1.6rem;
  text-align: center;
}
body.login .content-wrapper .nomail-info::before,
body.login .content-wrapper .nomail-info::after {
  content: ' ';
  display: table;
}
body.login .content-wrapper .nomail-info::after {
  clear: both;
}
body.login .content-wrapper .nomail-info p {
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-family: var(--font-family-sans-serif);
  margin: 0 0 0.4rem;
}
body.login .content-wrapper .email-admin {
  margin: 0 0 0.4rem;
  font-family: var(--font-family-sans-serif);
  font-size: 1.4rem;
  line-height: 1.8rem;
}
.inboxnews .co-box {
  padding: 0;
}
.has-ad-expanding-sky .co-sky {
  z-index: 1201;
}
.has-ad-expanding-sky .co-sky iframe {
  position: absolute;
  right: 0;
}
.co-container.floor-ad-desktop {
  width: 100%;
  height: 200px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1200;
}
.co-container.co-topbanner.interstitial {
  position: fixed;
  inset: 0;
  padding-top: 0;
}
.co-container.co-topbanner.interstitial > iframe {
  width: 100%;
  height: 100%;
}
/*
 * Logout ad on mobile homepage
 */
.co-logout {
  margin: 0.8rem auto !important;
  text-align: center;
}
/**
 * ad overlay top / sky
 *
 * part of the overlay module
 * e.g. in slideshow or single image overlay
 */
.ad-overlay-top {
  display: none;
}
@media (min-width:48em) {
  .ad-overlay-top {
    display: block;
    margin: 0 auto;
    padding: 1.6rem 0;
    min-height: 90px;
  }
  .ab-active .ad-overlay-top {
    min-height: 0;
    padding: 0;
  }
}
.ad-overlay-sky {
  display: none;
}
@media (min-width:64em) {
  .ad-overlay-sky {
    display: block;
    padding: 0 1.6rem;
    min-width: 160px;
  }
  .ad-overlay-sky .adv-generic-container > div {
    margin: 0;
  }
  .ab-active .ad-overlay-sky {
    min-width: 0;
    padding: 0;
  }
  .ad-overlay-sky.doubleRec {
    display: inline-table;
    margin-bottom: 1rem;
  }
  .ad-overlay-sky.ad-overlay-sky-2 {
    display: block;
  }
}
@media (min-width:73.75em) {
  .ad-overlay-sky:not(.doubleRec) {
    min-width: 300px;
    height: 100%;
  }
}
.ad-pushdown-small iframe {
  height: 7.5rem;
}
/**
 * Rectangle Ad
 */
.co-container.co-rectangle {
  display: none;
}
@media (min-width:64em) {
  .co-container.co-rectangle {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 30rem;
    padding: 1rem;
  }
  .js-navi-open .co-container.co-rectangle {
    display: none;
  }
}
.inboxnews .co-container.co-rectangle {
  display: block;
}
@media (min-width:80em) {
  .main .coc-sky .co-sky {
    text-align: left;
  }
  .main .coc-sky .co-sky.sitebar {
    min-width: 0;
    width: 0;
    margin-right: 0;
  }
  .main .coc-sky .co-sky .adv-generic-container.sticky {
    position: fixed;
    top: 1.6rem;
    right: 1.6rem;
  }
}
.main .coc-sky .co-sky.ad-sitebar {
  max-width: 848px;
  min-height: 600px;
  height: 100%;
  margin-right: 0;
  left: var(--ad-right-startpos);
}
.main .coc-sky .co-sky.ad-sitebar.fixed {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1500;
}
.main .coc-sky .co-sky.ad-sitebar > iframe {
  width: 100%;
  height: 100%;
}
.has-ad-widesky .main .coc-sky .co-sky.ad-sitebar {
  right: 1.6rem;
}
.ad-sitebar-slideshow {
  max-width: 320px;
  max-height: 765px;
}
.ad-sitebar-slideshow > iframe {
  width: 100%;
  height: 100%;
}
.co-container.ad-sticky-bottom {
  position: fixed;
  bottom: 1.5rem;
  left: 0;
  width: 100%;
}
.co-container.ad-sticky-bottom.ad-sticky-bottom-expanded {
  bottom: 0;
}
.co-container.co-sky {
  text-align: inherit;
}
.has-ad-widesky .ad-disabled.coc-sky {
  display: block !important;
  width: 316px;
  min-width: 316px;
}
.ad-sticky-top {
  position: fixed;
  width: 100%;
  z-index: 1199;
  background-color: var(--body-bg);
}
.mobile-exclusive-sticky-ad_close-button {
  display: inline-block;
  box-sizing: content-box;
  width: 30px;
  height: 30px;
  position: relative;
  border: 0;
  border-radius: 1em;
  font: normal 40px / normal Arial, Helvetica, sans-serif;
  color: #000000;
  text-overflow: clip;
  background: #000;
  z-index: 99999;
  cursor: pointer;
}
.mobile-exclusive-sticky-ad_close-button::before {
  display: inline-block;
  box-sizing: content-box;
  width: 0.45em;
  height: 0.1em;
  position: absolute;
  content: '';
  top: 0.33em;
  left: 0.155em;
  border: 0;
  font: normal 100% / normal Arial, Helvetica, sans-serif;
  color: #000000;
  text-overflow: clip;
  background: #fff;
  text-shadow: none;
  transform: rotateZ(45deg);
}
.mobile-exclusive-sticky-ad_close-button::after {
  display: inline-block;
  box-sizing: content-box;
  width: 0.45em;
  height: 0.1em;
  position: absolute;
  content: '';
  top: 0.33em;
  left: 0.155em;
  border: 0;
  font: normal 100% / normal Arial, Helvetica, sans-serif;
  color: #000000;
  text-overflow: clip;
  background: #fff;
  text-shadow: none;
  transform: rotateZ(-45deg);
}
/**
 * This file serves all definitions for tandem ads
 * A couple of these definitions are copied from ads.less (mod-ad).
 * As soon as we remove mod-ad these double definitions will be gone.
 */
/**
 * Tandem two-tag
 * delivered in two ads (top and sky)
 */
.co-container.ad-tandem-top {
  position: static;
}
.co-container.ad-tandem-top iframe {
  position: absolute;
  text-align: right;
  top: 16px;
  right: 16px;
}
.co-container.ad-tandem-sky iframe {
  position: relative;
  top: -16px;
}
/**
 * Tandem one-tag / singleton
 * delivered in one big ad (via top)
 */
.has-ad-tandem-singleton .coc-top .co-topbanner {
  position: absolute;
  right: 1.5rem;
  top: 0;
  z-index: 1;
}
.has-ad-tandem-singleton .coc-top > .tc {
  height: 10.6rem;
}
.has-ad-tandem-singleton .main .coc-sky .co-sky {
  min-width: auto;
}
.has-ad-tandem-singleton .content-wrapper > .content {
  z-index: 1;
}
.co-teaserline {
  box-sizing: border-box;
  border-bottom: 0.1rem solid var(--separator-default);
  overflow: hidden;
}
.mod-sement .co-teaserline,
[data-mod-name='sement'] .co-teaserline {
  border-top: 0.1rem solid var(--separator-default);
  border-bottom: 0.1rem solid var(--separator-default);
}
@media (min-width:48em) {
  .mod-sement .co-teaserline,
  [data-mod-name='sement'] .co-teaserline {
    text-align: left;
    padding-bottom: 1.6rem;
    padding-top: 1.6rem;
  }
}
.content .co-teaserline {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
@media (min-width:48em) {
  .content .co-teaserline {
    padding-bottom: 1.6rem;
    padding-top: 1.6rem;
  }
}
.ab-active .co-teaserline {
  display: none !important;
}
/**
 * Overview is shared across many modules, and as overview has only CSS,
 * is doesn't make sense to use data-mod-name
 */
.siteOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20001;
  background-color: #ddd;
}
.siteOverlay .overlayWrapper {
  display: table;
  height: 100%;
  width: 100%;
}
.siteOverlay .overlayWrapper .tr {
  display: table-row;
}
.siteOverlay .overlayWrapper .tr .tc {
  display: table-cell;
  vertical-align: top;
}
.siteOverlay .overlayWrapper .overlayContent {
  width: 100%;
  height: 100%;
}
.siteOverlay .overlayWrapper .overlayContent .ocw {
  position: relative;
  width: 100%;
  height: 100%;
}
.dialogOverlay {
  inset: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 20001;
  display: table;
  width: 100%;
  height: 100%;
  position: fixed;
  transition: background-color 0.3s ease-out;
}
.dialogOverlay.fadeIn {
  background-color: rgba(0, 0, 0, 0.8);
}
.dialogOverlay .dialogWrapper {
  display: table-cell;
  vertical-align: middle;
  padding: 0 1.6rem;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.dialogOverlay .dialogWrapper.opened {
  animation-name: open;
}
.dialogOverlay .dialogWrapper.closed {
  animation-name: close;
}
.dialogOverlay .dialogWrapper.bouncein {
  animation-name: bounceIn;
}
.dialogOverlay .dialogWrapper.bounceout {
  animation-name: bounceOut;
}
.dialogOverlay .dialogWrapper .close-bar {
  background-color: var(--white);
  height: 20px;
  text-align: right;
  margin: auto auto -2px;
  width: auto;
}
.dialogOverlay .dialogWrapper .close-bar .icon-close {
  position: relative;
  top: 12px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
  font-size: 2rem;
  line-height: 3.2rem;
}
@media (min-width:48em) {
  .dialogOverlay .dialogWrapper .close-bar .icon-close {
    top: 20px;
  }
}
.dialogOverlay .dialogWrapper .close-bar .icon-close:hover {
  color: var(--brand-signal);
}
.dialogOverlay .dialogWrapper .dialogContent {
  box-sizing: border-box;
  clear: both;
  margin: auto;
  width: auto;
  overflow: auto;
  position: relative;
  background-color: var(--page-background);
  color: var(--text-highVisibility);
  padding: 0 2rem 2rem;
}
@media (min-width:64em) {
  .dialogOverlay .dialogWrapper .dialogContent {
    padding: 0 3rem 2rem;
  }
}
.dialogOverlay[data-type='welcomelayer'] .dialogContent {
  padding: 0 2.4rem 2.1rem;
}
.dialogOverlay[data-type='welcomelayer'] .dialogWrapper .close-bar .icon-close {
  width: 3.2rem;
  top: 20px;
  right: 1.7rem;
  text-align: center;
}
.hideScrolling {
  overflow-y: hidden;
}
.addScrolling {
  overflow-y: auto;
}
[data-mod-name='welcomeback'] {
  overflow: hidden;
}
[data-mod-name='welcomeback'] .welcome {
  font-size: 2.4rem;
  line-height: 3.2rem;
}
[data-mod-name='welcomeback'] .welcome .logo-image {
  display: inline-block;
  width: var(--wb-logo-img-width);
  height: 3.2rem;
  margin-right: 1rem;
  background: var(--wb-logo-img);
  background-size: contain;
}
[data-mod-name='welcomeback'] .welcome .greetings {
  vertical-align: top;
  font-size: 2.6rem;
  font-family: var(--font-family-sans-serif);
  font-weight: bold;
  color: var(--text-highVisibility);
}
[data-mod-name='welcomeback'] .icon-close {
  width: 32px;
  position: absolute;
  right: 2.2rem;
  top: 1.6rem;
  text-align: center;
}
[data-mod-name='welcomeback'] .hint {
  font-size: 1.4rem;
  line-height: 1.8rem;
  float: right;
  color: var(--cp-grey-light-3);
  font-family: var(--font-family-sans-serif);
}
[data-mod-name='welcomeback'] .hint:hover {
  color: var(--brand-signal);
}
[data-mod-name='welcomeback'] .wbcontent {
  display: flex;
}
[data-mod-name='welcomeback'] .wbcontent__teasers {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2.4rem;
  border-top: 0.1rem solid var(--separator-default);
}
.ie11 [data-mod-name='welcomeback'] .wbcontent__teasers {
  display: block;
}
[data-mod-name='welcomeback'] .wbcontent.has-taboola .wbcontent__teasers {
  display: block;
}
[data-mod-name='welcomeback'] .wbcontent.has-taboola .wbcontent__hpad {
  display: none;
}
[data-mod-name='welcomeback'] .wbcontent__hpad {
  overflow: hidden;
  width: 300px;
  padding-left: 2.4rem;
}
[data-mod-name='welcomeback'] .co-wbhalfpage {
  width: 300px;
  height: 600px;
  margin-bottom: 1.8rem;
  overflow: hidden;
}
[data-mod-name='welcomeback'] [data-mod-name='newteaser'].teaser {
  align-self: flex-start;
  border: 0;
}
[data-mod-name='welcomeback'] [data-mod-name='newteaser'].teaser:nth-child(4),
[data-mod-name='welcomeback'] [data-mod-name='newteaser'].teaser:nth-child(5),
[data-mod-name='welcomeback'] [data-mod-name='newteaser'].teaser:nth-child(6) {
  margin-bottom: 0;
}
[data-mod-name='welcomeback'] [data-mod-name='newteaser'].teaser .teaser__text {
  padding: 1rem 0 0;
}
[data-mod-name='welcomeback'] [data-mod-name='newteaser'].teaser .teaser__headline {
  margin-bottom: 0;
}
@media (min-width:48em) and (max-width:1023px) {
  [data-mod-name='welcomeback'] .teaser-list-horizontal [data-mod-name='newteaser'].teaser {
    width: calc(100% / 2 - 2.4rem / 2);
  }
  [data-mod-name='welcomeback'] .teaser-list-horizontal [data-mod-name='newteaser'].teaser:nth-child(2n) {
    margin-right: 0;
  }
  [data-mod-name='welcomeback'] .teaser-list-horizontal [data-mod-name='newteaser'].teaser:nth-child(3n) {
    margin-right: auto;
  }
  [data-mod-name='welcomeback'] .teaser-list-horizontal [data-mod-name='newteaser'].teaser:nth-child(3) {
    margin-bottom: 0;
  }
  [data-mod-name='welcomeback'] .teaser-list-horizontal [data-mod-name='newteaser'].teaser:nth-child(5),
  [data-mod-name='welcomeback'] .teaser-list-horizontal [data-mod-name='newteaser'].teaser:nth-child(6) {
    display: none;
  }
}
[data-mod-name='welcomeback'] .button.primary {
  margin: 1rem 0 3.5rem;
}
@media (min-width:48em) and (max-width:1023px) {
  [data-mod-name='welcomeback'] .button.primary {
    padding: 0 1rem;
  }
}
[data-mod-name='welcomeback'] .co-taboola-wb {
  min-height: 69.9rem;
}
.imageFullScreen {
  background-color: var(--cp-grey-color);
  position: absolute;
  inset: 0;
  box-sizing: border-box;
  border-top: 0.4rem solid var(--brand-color);
}
.imageFullScreen .icon-close {
  font-size: 2rem;
  line-height: 5rem;
  color: var(--white);
}
.imageFullScreen .icon-close:hover {
  color: var(--brand);
}
.imageFullScreen .closeOverlay {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 1.5rem;
  background-color: rgba(51, 51, 51, 0.8);
  font-size: 1.4rem;
  z-index: 1;
}
.imageFullScreen .imgContainer {
  margin: auto;
  position: absolute;
  inset: 0 0 5.4rem;
}
.imageFullScreen .imgContainer img {
  height: 100%;
  width: auto;
}
.imageFullScreen figcaption {
  border-top: 1px solid var(--separator-default);
  position: absolute;
  bottom: -5.7rem;
  left: 0;
  right: 0;
  width: 100%;
  padding: 1.6rem 6.25%;
  box-sizing: border-box;
  background-color: rgba(51, 51, 51, 0.8);
  color: var(--white);
  z-index: 20011;
}
.imageFullScreen figcaption.is-extendable {
  cursor: pointer;
}
.imageFullScreen figcaption.is-extendable .icon-subtext {
  display: block;
}
.imageFullScreen figcaption .caption {
  width: auto;
  height: 2.4rem;
  transition: height 0.6s ease 0s;
  margin-right: 3.5rem;
  overflow: hidden;
  font-size: 1.4rem;
}
.imageFullScreen figcaption .description {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.imageFullScreen figcaption .copyright {
  color: var(--cp-grey-light-3);
  margin-right: 0.8rem;
  font-size: 1.2rem;
}
.imageFullScreen figcaption .copyright::first-letter {
  font-size: 16px;
  line-height: 1.8rem;
  font-weight: 300;
}
.imageFullScreen figcaption .icon-subtext {
  font-size: 2rem;
  line-height: 2.4rem;
  transition: all 0.3s ease 0s;
  transform: rotate(0deg);
  float: right;
  cursor: pointer;
  display: none;
}
.imageFullScreen figcaption .icon-subtext::before {
  content: '\e608';
  vertical-align: top;
}
.imageFullScreen figcaption.opened .caption {
  height: auto;
}
.imageFullScreen figcaption.opened .description {
  white-space: normal;
}
.imageFullScreen figcaption.opened .icon-subtext {
  transform: rotate(-180deg);
}
.imageFullScreen .captionGradient {
  height: 20rem;
  background-image: linear-gradient(to bottom, transparent 0%, #000 100%);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.imageFullScreen .bgimg {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
}
.feedback__dialog {
  padding: 0 2rem 2rem;
  border-radius: 2px;
  border: 0;
  text-align: left;
  max-width: 92rem;
  background: var(--surface-overlay);
}
.feedback__dialog[open] {
  animation: bounceIn 0.8s ease;
}
.feedback__dialog::backdrop {
  background: rgba(0, 0, 0, 0.85);
}
.feedback__dialog.close {
  animation: bounceOut 0.8s ease;
}
.feedback__dialog.closeSuccess {
  animation: close 0.8s ease;
}
.feedback__dialog__close {
  background-color: var(--surface-overlay);
  height: 20px;
  text-align: right;
  margin: auto auto -2px;
  width: auto;
}
.feedback__dialog__close .icon-close {
  position: relative;
  top: 1.2rem;
  z-index: 1;
  cursor: pointer;
  font-size: 2rem;
  line-height: 3.2rem;
  color: var(--text-highVisibility);
}
.feedback__dialog__close .icon-close:hover {
  color: var(--brand-signal);
}
